import React, { useEffect, useState } from "react";
import "../month.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Player from "../../Player";

import im1 from "../../assets/images/mar/1.jpeg";
import im2 from "../../assets/images/mar/2.jpeg";
import im3 from "../../assets/images/mar/3.jpeg";
import im4 from "../../assets/images/mar/4.jpeg";
import im5 from "../../assets/images/mar/5.jpeg";
import im6 from "../../assets/images/mar/6.jpeg";
import im7 from "../../assets/images/mar/7.jpeg";
import im8 from "../../assets/images/mar/8.jpeg";
import im9 from "../../assets/images/mar/9.jpeg";
import im10 from "../../assets/images/mar/10.jpeg";
import im11 from "../../assets/images/mar/11.jpeg";
import im12 from "../../assets/images/mar/12.jpeg";
import im13 from "../../assets/images/mar/13.jpeg";
import im14 from "../../assets/images/mar/14.jpeg";
import im15 from "../../assets/images/mar/15.jpeg";
import im16 from "../../assets/images/mar/16.jpeg";
import im17 from "../../assets/images/mar/17.jpeg";

import { Link } from "react-router-dom";

import mar from "../../assets/music/mar.mp3"; //Jab koi baat bigad jaaye
export default function March() {
  useEffect(() => {
    console.log("here");
    localStorage.removeItem("reloadCountMain");
    const reloadCount = localStorage.getItem("reloadCount");
    var count;
    if (!reloadCount) {
      count = 0;
    } else {
      count = parseInt(reloadCount);
    }

    if (count < 2) {
      localStorage.setItem("reloadCount", String(count + 1));
      window.location.reload();
    } else {
      // localStorage.removeItem('reloadCount');
    }
  }, []);
  const [carSettings, setCarSettings] = useState();

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true,
  };
  var settings2 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true
    };
  var settings3 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true  
  };
  useEffect(() => {
    window.innerWidth > 1000
      ? setCarSettings(settings)
      : window.innerWidth > 800
      ? setCarSettings(settings2)
      : setCarSettings(settings3);
  }, [window.innerWidth]);


  return (
    <div className="month_main">
      <div className="month_title">March💞</div>

      <div className="month_text">
      <p className="month_text_big">Hi babyyy... Welcome to March...</p>
        <p className="month_text_big">
          <i>5 Months To Go For Your Birthday!! ❤️🌍</i>
        </p>
        <p>
          March was finally here, the time we had been looking foward to for so
          many months had arrived, we both were off to college... together!!
          ❤️❤️❤️❤️
        </p>
        <p>
          This month had a lot of firsts, our first time travelling together,
          our first proper date, our first holi together 🥺❤️, our first trip to
          the beach together and so much more!! 💞💞💞
        </p>
        
        <p>
          Waking up every morning, rushing to meet you, spending the entire day
          with you, it all just felt like a dream come true, I had never felt as
          complete as I did with you by my side.🌍🌍🌍🌍
        </p>
      </div>
      <Player url={mar} />
      <div className="imgslider">
        <Slider {...carSettings}>
          <div>
            <img src={im1} className="month_img" />
          </div>
          <div>
            <img src={im2} className="month_img" />
          </div>
          <div>
            <img src={im3} className="month_img" />
          </div>
          <div>
            <img src={im4} className="month_img" />
          </div>
          <div>
            <img src={im5} className="month_img" />
          </div>
          <div>
            <img src={im6} className="month_img" />
          </div>
          <div>
            <img src={im7} className="month_img" />
          </div>
          <div>
            <img src={im8} className="month_img" />
          </div>
          <div>
            <img src={im9} className="month_img" />
          </div>
          <div>
            <img src={im10} className="month_img" />
          </div>
          <div>
            <img src={im11} className="month_img" />
          </div>
          <div>
            <img src={im12} className="month_img" />
          </div>
          <div>
            <img src={im13} className="month_img" />
          </div>
          <div>
            <img src={im14} className="month_img" />
          </div>
          <div>
            <img src={im15} className="month_img" />
          </div>
          <div>
            <img src={im16} className="month_img" />
          </div>
          <div>
            <img src={im17} className="month_img" />
          </div>
        </Slider>
      </div>
      <div className="sub_text1">I LOVE YOU!❤️</div>
      <Link to={"/"}>
        <div className="back_link">Back To Home</div>
      </Link>
    </div>
  );
}
