import React, { useEffect, useState } from "react";
import "../month.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Player from "../../Player";

import im1 from "../../assets/images/jun/1.jpeg";
import im2 from "../../assets/images/jun/2.jpeg";
import im3 from "../../assets/images/jun/3.jpeg";
import im4 from "../../assets/images/jun/4.jpeg";
import im5 from "../../assets/images/jun/5.jpeg";
import im6 from "../../assets/images/jun/6.jpeg";
import im7 from "../../assets/images/jun/7.jpeg";
import im8 from "../../assets/images/jun/8.jpeg";

import { Link } from "react-router-dom";

import jun from "../../assets/music/jun.mp3"; //Jab koi baat bigad jaaye
export default function June() {
  useEffect(() => {
    console.log("here");
    localStorage.removeItem("reloadCountMain");
    const reloadCount = localStorage.getItem("reloadCount");
    var count;
    if (!reloadCount) {
      count = 0;
    } else {
      count = parseInt(reloadCount);
    }

    if (count < 2) {
      localStorage.setItem("reloadCount", String(count + 1));
      window.location.reload();
    } else {
      // localStorage.removeItem('reloadCount');
    }
  }, []);
  const [carSettings, setCarSettings] = useState();

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true,
  };
  var settings2 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true
    };
  var settings3 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true  
  };
  useEffect(() => {
    window.innerWidth > 1000
      ? setCarSettings(settings)
      : window.innerWidth > 800
      ? setCarSettings(settings2)
      : setCarSettings(settings3);
  }, [window.innerWidth]);



  return (
    <div className="month_main">
      <div className="month_title">June💍</div>

      <div className="month_text">
        <p className="month_text_big">Hi babyyy... Welcome to June...</p>
        <p className="month_text_big">
          <i>2 Months To Go For Your Birthday!! ❤️🌍</i>
        </p>
        <p>
          Babbyyy, well coz of exams, we didn't get much quality time together
          for a couple of weeks in June, didn't get to go out anywhere just the
          two of us.🥺🥺🥺
        </p>
        <p>
          But this month had a day which I will always hold close to my heart,
          24th of June, the day you became mine! ❣️❣️ <br />
          It was our one year anniversary, and I couldn't believe that it had
          been a year since you had been MINE 🌍❤️
        </p>
        <p>
          Sometimes it just felt as though the past year had flown by.. the
          highs, the lows, all the love, it has been so special for me! I can't
          even begin to thank you enough for coming into my life baby!! 💞💞💞💞
        </p>
        <p>
          And well, finally you came to bombay, drove around with me, even
          though it was only for a couple hours, felt so good to be with you in
          bombay, show you how a good vada pav is supposed to taste like. 🤭🤭
        </p>
        <p>
          And then you left... I had no idea how I was going to survive one
          month of being away from you.. but I couldn't wait to see you again!
          🥺🌍🥺🌍
        </p>
      </div>
      <Player url={jun} />
      <div className="imgslider">
        <Slider {...carSettings}>
          <div>
            <img src={im1} className="month_img" />
          </div>
          <div>
            <img src={im2} className="month_img" />
          </div>
          <div>
            <img src={im3} className="month_img" />
          </div>
          <div>
            <img src={im4} className="month_img" />
          </div>
          <div>
            <img src={im5} className="month_img" />
          </div>
          <div>
            <img src={im6} className="month_img" />
          </div>
          <div>
            <img src={im7} className="month_img" />
          </div>
          <div>
            <img src={im8} className="month_img" />
          </div>
        </Slider>
      </div>
      <div className="sub_text1">I LOVE YOU!❤️</div>
      <Link to={"/"}>
        <div className="back_link">Back To Home</div>
      </Link>
    </div>
  );
}
