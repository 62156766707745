import React, { useEffect, useState } from "react";
import "../month.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Player from "../../Player";

import im1 from "../../assets/images/oct/1.jpeg";
import im2 from "../../assets/images/oct/2.jpeg";
import im3 from "../../assets/images/oct/3.jpeg";
import im4 from "../../assets/images/oct/4.jpeg";
import im5 from "../../assets/images/oct/5.jpeg";

import { Link } from "react-router-dom";

import oct from "../../assets/music/oct.mp3"; //I love you
export default function October() {
  useEffect(() => {
    console.log("here");
    localStorage.removeItem("reloadCountMain");
    const reloadCount = localStorage.getItem("reloadCount");
    var count;
    if (!reloadCount) {
      count = 0;
    } else {
      count = parseInt(reloadCount);
    }

    if (count < 2) {
      localStorage.setItem("reloadCount", String(count + 1));
      window.location.reload();
    } else {
      // localStorage.removeItem('reloadCount');
    }
  }, []);
  const [carSettings, setCarSettings] = useState();

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true,
  };
  var settings2 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true
    };
  var settings3 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true  
  };
  useEffect(() => {
    window.innerWidth > 1000
      ? setCarSettings(settings)
      : window.innerWidth > 800
      ? setCarSettings(settings2)
      : setCarSettings(settings3);
  }, [window.innerWidth]);


  return (
    <div className="month_main">
      <div className="month_title">October💗</div>

      <div className="month_text">
        <p className="month_text_big">Hi babyyy... Welcome to October...</p>
        <p className="month_text_big">
          <i>10 Months To Go For Your Birthday!! ❤️🌍</i>
        </p>
        <p>
          Remember October baby... not such an easy month for us... me
          undergoing my surgery, us not being able to talk at all for 5 days.🥺
        </p>
        <p>
          I remember how worried you were before and during my surgery...
          <br />
          Chalo issi bahane you messaged my father for the first time.🤭🤭🤭
        </p>
        <p>
          And then you... travelling back to campus, all alone... knowing that I
          would not be there with you in campus! 🥺🥺
        </p>
        <p>
          But I know that whatever I went through tab, whatever we went
          through... I couldn't have done it without you by my side, so thank
          you sooo much darling for being such a constant source of love and
          support in my life!!
        </p>
        <b>I Love You!❤️🌍❤️🌍</b>
      </div>
      <Player url={oct} />
      <div className="imgslider">
        <Slider {...carSettings}>
          <div>
            <img src={im1} className="month_img" />
          </div>
          <div>
            <img src={im2} className="month_img" />
          </div>
          <div>
            <img src={im3} className="month_img" />
          </div>
          <div>
            <img src={im4} className="month_img" />
          </div>
          <div>
            <img src={im5} className="month_img" />
          </div>
        </Slider>
      </div>
      <div className="sub_text1">I LOVE YOU!❤️</div>
      <Link to={"/"}>
        <div className="back_link">Back To Home</div>
      </Link>
    </div>
  );
}
