import React, { useEffect, useState } from "react";
import "../month.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Player from "../../Player";

import im1 from "../../assets/images/sept/1.jpeg";
import im2 from "../../assets/images/sept/2.jpeg";
import im3 from "../../assets/images/sept/3.jpeg";
import { Link } from "react-router-dom";

import sept from "../../assets/music/sept.mp3"; //Jab koi baat bigad jaaye
export default function September() {
  useEffect(() => {
    console.log("here");
    localStorage.removeItem("reloadCountMain");
    const reloadCount = localStorage.getItem("reloadCount");
    var count;
    if (!reloadCount) {
      count = 0;
    } else {
      count = parseInt(reloadCount);
    }

    if (count < 2) {
      localStorage.setItem("reloadCount", String(count + 1));
      window.location.reload();
    } else {
      // localStorage.removeItem('reloadCount');
    }
  }, []);
  const [carSettings, setCarSettings] = useState();

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true,
  };
  var settings2 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true
    };
  var settings3 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true  
  };
  useEffect(() => {
    window.innerWidth > 1000
      ? setCarSettings(settings)
      : window.innerWidth > 800
      ? setCarSettings(settings2)
      : setCarSettings(settings3);
  }, [window.innerWidth]);


  return (
    <div className="month_main">
      <div className="month_title">September❤️</div>

      <div className="month_text">
        <p className="month_text_big">Hi babyyy... Welcome to September...</p>
        <p className="month_text_big">
          <i>11 Months To Go For Your Birthday!! ❤️🌍</i>
        </p>
        <p>
          Sorry I don't have many pictures from this month darling.🥺🥺
          September just went by in us fighting with each other, loving each
          other, getting closer with each day, doing lots of EG (hehehe fun
          times. 🤭🤭), and me getting ready for my surgery.🥺
        </p>
      </div>
      <Player url={sept} />
      <div className="imgslider">
        <Slider {...carSettings}>
          <div>
            <img src={im1} className="month_img" />
          </div>
          <div>
            <img src={im2} className="month_img" />
          </div>
          <div>
            <img src={im3} className="month_img" />
          </div>
          <div>
            <img src={im1} className="month_img" />
          </div>
          <div>
            <img src={im2} className="month_img" />
          </div>
          <div>
            <img src={im3} className="month_img" />
          </div>
        </Slider>
      </div>
      <div className="sub_text1">I LOVE YOU!❤️</div>
      <Link to={"/"}>
        <div className="back_link">Back To Home</div>
      </Link>
    </div>
  );
}
