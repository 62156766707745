import "./App.css";
import Main from "./Main";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import September from "./months/September/September";
import October from "./months/October/October";
import November from "./months/November/November";
import December from "./months/December/December";
import January from "./months/January/January";
import February from "./months/February/Febraury";
import March from "./months/March/March";
import April from "./months/April/April";
import May from "./months/May/May";
import June from "./months/June/June";
import July from "./months/July/July";
import August from "./months/August/August";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/sept" element={<September />} />
          <Route path="/oct" element={<October />} />
          <Route path="/nov" element={<November />} />
          <Route path="/dec" element={<December />} />
          <Route path="/jan" element={<January />} />
          <Route path="/feb" element={<February/>} />
          <Route path="/mar" element={<March/>} />
          <Route path="/apr" element={<April/>} />
          <Route path="/may" element={<May/>} />
          <Route path="/jun" element={<June/>} />
          <Route path="/jul" element={<July/>} />
          <Route path="/aug" element={<August/>} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
