import React, { useEffect, useState } from "react";
import "../month.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Player from "../../Player";

import im1 from "../../assets/images/aug/1.jpeg";
import im2 from "../../assets/images/aug/2.jpeg";
import im3 from "../../assets/images/aug/3.jpeg";
import im4 from "../../assets/images/aug/4.jpeg";
import im5 from "../../assets/images/aug/5.jpeg";
import im6 from "../../assets/images/aug/6.jpeg";
import im7 from "../../assets/images/aug/7.jpeg";
import im8 from "../../assets/images/aug/8.jpeg";

import { Link } from "react-router-dom";

import aug from "../../assets/music/aug.mp3";
export default function August() {
  
  useEffect(() => {
    console.log("here");
    localStorage.removeItem("reloadCountMain");
    const reloadCount = localStorage.getItem("reloadCount");
    var count;
    if (!reloadCount) {
      count = 0;
    } else {
      count = parseInt(reloadCount);
    }

    if (count < 2) {
      localStorage.setItem("reloadCount", String(count + 1));
      window.location.reload();
    } else {
    }
  }, []);

  const [carSettings, setCarSettings] = useState();

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true,
  };
  var settings2 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true
    };
  var settings3 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true  
  };
  useEffect(() => {
    window.innerWidth > 1000
      ? setCarSettings(settings)
      : window.innerWidth > 800
      ? setCarSettings(settings2)
      : setCarSettings(settings3);
  }, [window.innerWidth]);
  return (
    <div className="month_main">
      <div className="month_title">August❤️</div>

      <div className="month_text">
        <p className="month_text_big">Hi babyyy... Welcome to August❤️</p>
        <p className="month_text_big">
          <i>It's finallyyy your birthday month... Yaaaaayyyyyyy❤️🌍</i>
        </p>
        <p>
          The month of August was honestly so eventful... the most signficant
          part was obviously you meeting my family FINALLY! ❤️❤️
        </p>
        <p>
          When you were having dinner with my family, all 5 of us together, I
          just felt so complete babbyy... everything just felt so perfect❣️❣️❣️
        </p>
        <p>
          You loved my family, they loved you, what more do I need in life
          haaa🥺🥺🥺
        </p>
        <p>
          After that, the rest of this month, we both have been quite busy in
          our work and academics darling (I have also been quite busy working on
          your gifts 🤭🤭🤭){" "}
        </p>
        <p>
          But still, even the little time that we did get together, every
          second, every minute, even with all the stress of work we found our
          calm in each other, we found our peace in each other and darling
          that's all I want to do for the rest of our lives. 
        </p>
      </div>
      <Player url={aug} />
      <div className="imgslider">
        <Slider {...carSettings}>
          <div>
            <img src={im1} className="month_img" />
          </div>
          <div>
            <img src={im2} className="month_img" />
          </div>
          <div>
            <img src={im3} className="month_img" />
          </div>
          <div>
            <img src={im4} className="month_img" />
          </div>
          <div>
            <img src={im5} className="month_img" />
          </div>
          <div>
            <img src={im6} className="month_img" />
          </div>
          <div>
            <img src={im7} className="month_img" />
          </div>
          <div>
            <img src={im8} className="month_img" />
          </div>
        </Slider>
      </div>
      <div className="sub_text1">I LOVE YOU!❤️</div>
      <Link to={"/"}>
        <div className="back_link">Back To Home</div>
      </Link>
    </div>
  );
}
