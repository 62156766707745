import React, { useEffect, useState } from "react";
import "../month.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Player from "../../Player";

import im1 from "../../assets/images/may/1.jpeg";
import im2 from "../../assets/images/may/2.jpeg";
import im3 from "../../assets/images/may/3.jpeg";
import im4 from "../../assets/images/may/4.jpeg";
import im5 from "../../assets/images/may/5.jpeg";
import im6 from "../../assets/images/may/6.jpeg";
import im7 from "../../assets/images/may/7.jpeg";
import im8 from "../../assets/images/may/8.jpeg";
import im9 from "../../assets/images/may/9.jpeg";
import im10 from "../../assets/images/may/10.jpeg";
import im11 from "../../assets/images/may/11.jpeg";
import im12 from "../../assets/images/may/12.jpeg";
import im13 from "../../assets/images/may/13.jpeg";

import { Link } from "react-router-dom";

import may from "../../assets/music/may.mp3"; //Jab koi baat bigad jaaye
export default function May() {
  useEffect(() => {
    console.log("here");
    localStorage.removeItem("reloadCountMain");
    const reloadCount = localStorage.getItem("reloadCount");
    var count;
    if (!reloadCount) {
      count = 0;
    } else {
      count = parseInt(reloadCount);
    }

    if (count < 2) {
      localStorage.setItem("reloadCount", String(count + 1));
      window.location.reload();
    } else {
      // localStorage.removeItem('reloadCount');
    }
  }, []);
  const [carSettings, setCarSettings] = useState();

  const settings = {
    infinite: true,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true,
  };
  var settings2 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true
    };
  var settings3 = {
    infinite: true,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 1000,
    centerMode: true,
    pauseOnHover: true,
    swipeToSlide: true  
  };
  useEffect(() => {
    window.innerWidth > 1000
      ? setCarSettings(settings)
      : window.innerWidth > 800
      ? setCarSettings(settings2)
      : setCarSettings(settings3);
  }, [window.innerWidth]);


  return (
    <div className="month_main">
      <div className="month_title">May😘</div>

      <div className="month_text">
        <p className="month_text_big">Hi babyyy... Welcome to May...</p>
        <p className="month_text_big">
          <i>3 Months To Go For Your Birthday!! ❤️🌍</i>
        </p>
        <p>The month of May... well it had its highs and lows...</p>
        <p>
          Parts of it were rough for us, not getting enough time with each other
          due to work and academics... being so close to each other yet so far
          apart.. it was not easy.
        </p>
        <p>
          But like we always do, we overcame it all got stronger together❤️❤️❤️
          <br />
          From long walk, to trips to the beach and kayaking... We did it all
          together.
        </p>
        <p>
          But honestly, as long as we are together, it doesn't matter what we
          are doing be it just sitting and cuddling with each other for hours or
          going out somewhere, every moment spent with you is just perfect!🌍🌍
        </p>
      </div>
      <Player url={may} />
      <div className="imgslider">
        <Slider {...carSettings}>
          <div>
            <img src={im1} className="month_img" />
          </div>
          <div>
            <img src={im2} className="month_img" />
          </div>
          <div>
            <img src={im3} className="month_img" />
          </div>
          <div>
            <img src={im4} className="month_img" />
          </div>
          <div>
            <img src={im5} className="month_img" />
          </div>
          <div>
            <img src={im6} className="month_img" />
          </div>
          <div>
            <img src={im7} className="month_img" />
          </div>
          <div>
            <img src={im8} className="month_img" />
          </div>
          <div>
            <img src={im9} className="month_img" />
          </div>
          <div>
            <img src={im10} className="month_img" />
          </div>
          <div>
            <img src={im11} className="month_img" />
          </div>
          <div>
            <img src={im12} className="month_img" />
          </div>
          <div>
            <img src={im13} className="month_img" />
          </div>
        </Slider>
      </div>
      <div className="sub_text1">I LOVE YOU!❤️</div>
      <Link to={"/"}>
        <div className="back_link">Back To Home</div>
      </Link>
    </div>
  );
}
